$primary: #2769ce !default;
$primary-alt: #f42a41 !default;
$primary-informa: #2f80ed !default;
$primary-einforma: #194eb1 !default;

// neutral
$white: #ffffff !default;
$black: #000000 !default;
$super-light: #f5f5f6 !default;
$light: #dcdfe0 !default;
$grey: #babcbf !default;
$darker: #494e54 !default;
$dark: #333333 !default;

$light-grey: #e6e6e6 !default;
$pearl-grey: #8a898d !default;
$pale-grey: #f0f4f9 !default;
$pale-grey-two: #fafafb !default;

// blue
$faded-blue: #6b92c0 !default;
$french-blue: #416ca2 !default;
$light-blue: #9bc3f3 !default;
$dark-indigo: #07132f !default;
$dark-grey-blue: #384258 !default;

// green
$green: #92c62d !default;
$icky-green: #84b622 !default;
$light-yellow-green: #ccf381 !default;

// orange
$pumpkin-orange: #ff7800 !default;
$bright-orange: #f45600 !default;
$sun-yellow: #ffd02b !default;

// red
$carmine: #a60014 !default;
$faded-red: #ca515f !default;

// alerts
$off-white: #fff8e1;
$light-tan: #f9e4a4;
$muddy-brown: #856404;
$ice: #e5f4e9;
$light-mint: #c3e6cb;
$pine-green: #155724;
$light-pink: #fbe7e9;
$pale-rose: #f5c6cb;
$plum: #721c24;
$ice-blue: #e7f1fc;
$powder-blue: #b8daff;
$darkish-blue: #004085;


// brand
$facebook_color: #365397 !default;
$twitter_color: #00a9f1 !default;
$linkedin_color: #006db3 !default;
$instagram_color: #8a3ab9 !default;
$youtube_color: #ff3333 !default;
$pinterest_color: #ce1a19 !default;
$google_plus_color: #e0452c !default;
$vimeo_color: #48b6ed !default;


// elements
$anchor__color: $primary !default;

$highlight-color: $green !default;
$price-color: $icky-green !default;
$error-color: $primary-alt !default;
$warning-color: $sun-yellow !default;
$success-color: $green !default;
$alert-color: $pumpkin-orange !default;

$informanager-color: $icky-green !default;

$base-color: $darker !default;
$title-color: $dark !default;
$pretitle-color: $base-color !default;
$subtitle-color: $dark !default;

$input-border-color: $light !default;
$input-focus: $primary !default;
$input-disabled: $super-light !default;

$tag-background: $green !default;
$tag-color: $white !default;

$tooltip-color: $white !default;
$tooltip-bg: rgba($black, .85) !default;

$overlay_bg: rgba($dark-indigo, 0.8) !default;
