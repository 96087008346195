// COMMON

@import "common/common-ie10";

//EINFORMA

.c-user-top-menu__user::after {
  top: 50%;
}

.c-compare-table {
  overflow: auto;

  &__cell:nth-child(2n) {
    background: rgba($light, 0.2);

    &::before {
      display: none;
    }
  }
}

.c-compare-table-simple {

  &__table {
    display: flex;
  }

  &__cell:nth-child(2n) {
    background: rgba($light, 0.2);

    &::before {
      display: none;
    }
  }
}

.c-app {

  &__button {

    img {
      width: 100%;
    }
  }
}

.c-band-media-links {

  &__media {

    img,
    svg {
      width: 100%;
    }
  }
}

.c-footer {

  &__apps-list-item {

    img {
      min-width: 100px;
      width: 100%;
    }
  }
}

.c-card-report {

  &__includes {
    margin-bottom: rem(20px);
  }
}

.c-dashboard__widget {

  .o-grid {
    width: 100%;
  }
}

.c-file-head {

  &__report {
    height: rem(48px);
    display: block;
    flex-wrap: nowrap;

    > span {
      flex: 1 1 auto;
      width: calc(100% - 110px);
      display: inline-block;
      vertical-align: middle;
    }

    > .c-file-head__report-price {
      flex: 0 0 rem(100px);
      width: rem(100px);
      margin-left: auto;

      span {
        white-space: nowrap;
      }
    }
  }
}

.c-card-av-report {

  &__price {

    > span {
      display: block;
    }
  }

  &__include {
    display: inline-block;
  }
}
