// Nexa Light
@font-face {
  font-family: 'Nexa';
  src: url('../fonts/common/nexa_light-webfont.eot');
  src: url('../fonts/common/nexa_light-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/nexa_light-webfont.woff') format('woff'),
        url('../fonts/common/nexa_light-webfont.ttf') format('truetype'),
        url('../fonts/common/nexa_light-webfont.svg#Nexa') format('svg');
  font-weight: normal;
  font-style: normal;
}

// Nexa Bold
@font-face {
  font-family: 'Nexa';
  src: url('../fonts/common/nexa_bold-webfont.eot');
  src: url('../fonts/common/nexa_bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/nexa_bold-webfont.woff') format('woff'),
        url('../fonts/common/nexa_bold-webfont.ttf') format('truetype'),
        url('../fonts/common/nexa_bold-webfont.svg#Nexa') format('svg');
  font-weight: 700;
  font-style: normal;
}

// Roboto Thin
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-thin-webfont.eot');
  src: url('../fonts/common/roboto-thin-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-thin-webfont.woff') format('woff'),
        url('../fonts/common/roboto-thin-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-thin-webfont.svg#Roboto') format('svg');
  font-weight: 100;
  font-style: normal;
}

// Roboto Light
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-light-webfont.eot');
  src: url('../fonts/common/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-light-webfont.woff') format('woff'),
        url('../fonts/common/roboto-light-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-light-webfont.svg#Roboto') format('svg');
  font-weight: 300;
  font-style: normal;
}

// Roboto Regular
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-regular-webfont.eot');
  src: url('../fonts/common/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-regular-webfont.woff') format('woff'),
        url('../fonts/common/roboto-regular-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-regular-webfont.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

// Roboto Regular italic
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-italic-webfont.eot');
  src: url('../fonts/common/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-italic-webfont.woff') format('woff'),
        url('../fonts/common/roboto-italic-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-italic-webfont.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: italic;
}

// Roboto Medium
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-medium-webfont.eot');
  src: url('../fonts/common/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-medium-webfont.woff') format('woff'),
        url('../fonts/common/roboto-medium-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-medium-webfont.svg#Roboto') format('svg');
  font-weight: 500;
  font-style: normal;
}

// Bold
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-bold-webfont.eot');
  src: url('../fonts/common/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-bold-webfont.woff') format('woff'),
        url('../fonts/common/roboto-bold-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-bold-webfont.svg#Roboto') format('svg');
  font-weight: 700;
  font-style: normal;
}

// Black
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/common/roboto-black-webfont.eot');
  src: url('../fonts/common/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/common/roboto-black-webfont.woff') format('woff'),
        url('../fonts/common/roboto-black-webfont.ttf') format('truetype'),
        url('../fonts/common/roboto-black-webfont.svg#Roboto') format('svg');
  font-weight: 900;
  font-style: normal;
}

// ICONS INFORMA
@font-face {
  font-family: 'ico-informa';
  src: url('../fonts/common/ico-informa/ico-informa.eot?22fpan');
  src: url('../fonts/common/ico-informa/ico-informa.eot?22fpan#iefix') format('embedded-opentype'),
        url('../fonts/common/ico-informa/ico-informa.ttf?22fpan') format('truetype'),
        url('../fonts/common/ico-informa/ico-informa.woff?22fpan') format('woff'),
        url('../fonts/common/ico-informa/ico-informa.svg?22fpan#ico-informa') format('svg');
  font-weight: normal;
  font-style: normal;
}
