@mixin divider-bottom($position: center, $color: $primary, $width: 40px, $top: 16px) {
  &::after {
    content: '';
    width: $width;
    display: block;
    border-top: 1px solid $color;
    margin: rem($top) auto 0;

    @if $position != 'center' {
      margin-#{$position}: 0;
    }
  }
}

@mixin divider-top($position: center, $color: $primary, $width: 40px, $bottom: 16px) {
  &::before {
    content: '';
    width: $width;
    display: block;
    border-top: 1px solid $color;
    margin: 0 auto rem($bottom);

    @if $position != 'center' {
      margin-#{$position}: 0;
    }
  }
}

@mixin hidden() {

  .u-hide {
    display: none !important;
  }

  @each $bp-key, $bp-value in $breakpoints {
    @media (min-width: $bp-value) {

      .u-hide#{$breakpoint-class}#{$bp-key} {
        display: none !important;
      }
    }
  }

  @each $bp-key, $bp-value in $breakpoints-max {
    @media (max-width: $bp-value) {

      .u-hide#{$breakpoint-class}#{$bp-key} {
        display: none !important;
      }
    }
  }
}

@mixin horizontal-gradient($start-color, $end-color, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
}

@mixin vertical-gradient($start-color, $end-color, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
}

@mixin vertical-gradient-three-colors($start-color: $white, $mid-color: #f4f4f4, $color-stop: 50%, $end-color: #d8d8d8) {
  background-image: linear-gradient(to bottom, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    box-shadow: inset $top $left $blur $color;
  }

  @else {
    box-shadow: $top $left $blur $color;
  }
}

@mixin icon-styles() {
  font-family: 'ico-informa' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin screen-readers() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin reset-list() {
  list-style: none;
  padding-left: 0;
}

@mixin bullets-list($bullet-color: $light-blue, $font-size: 14px, $margin-li: $lists__margin-bottom) {
  @include reset-list();

  font-size: rem($font-size);
  margin: 0 0 $paragraphs__margin-bottom;
  text-align: left;

  li {
    margin-bottom: $margin-li;
    padding-left: 24px;

    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: rem(5px);
      height: rem(5px);
      border-radius: 50%;
      background: $bullet-color;
      position: relative;
      left: rem(-16px);
      margin: rem(-1px) 0 0 rem(-5px);
    }
  }
}

@mixin check-list($check-color: $light-blue, $check-size: 10px) {
  $check-unit: $check-size / 10;

  @include reset-list();

  margin: 0 0 $paragraphs__margin-bottom;
  text-align: left;

  li {
    padding-left: rem(($check-unit * 25));
    margin-bottom: $lists__margin-bottom;

    &::before {
      @include icon-styles();

      content: $u-icon-check;
      color: $check-color;
      font-size: rem($check-size);
      width: rem(($check-unit * 16));
      margin: 0 ($check-unit * 9) 0 (-$check-unit * 25);
      text-align: center;
    }
  }
}

@mixin vertical-line() {
  position: relative;

  &::after {
    content: '';
    background-color: rgba($white,.5);
    bottom: 0;
    height: 50px;
    left: 50%;
    position: absolute;
    right: 50%;
    width: 1px;
    z-index: z(default, over);
  }
}

@mixin bg-overlay() {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $primary-einforma;
    opacity: 0.9;
    z-index: z(default);
  }

  > * {
    position: relative;
    z-index: (z(default)+1);
  }
}

@mixin mask($topLeft: 0 0, $topRight: 100% 0, $bottomRight: 100% 100%, $bottomLeft: 0 100%){
  -webkit-clip-path: polygon($topLeft, $topRight, $bottomRight, $bottomLeft);
  clip-path: polygon($topLeft, $topRight, $bottomRight, $bottomLeft);
}

@mixin imgRetina($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {

  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";

  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio: 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
  }
}

@mixin modal-paddings() {
  padding: rem(53px) 0 rem(16px);

  @media (min-width: $md) {
    padding: rem(64px) 0;
  }
}

@mixin text-ellipsis() {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
