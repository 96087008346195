/* Common settings and tools */
@import "common/01_settings/settings";
@import "common/02_tools/tools";

// COMMON

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

select::-ms-expand {
  display: none;
}

.c-form {

  &__datepicker {

    &::before {
      display: none;
    }

    input {
      @include imgRetina("../images/common/icon/calendar", "png", 24px, 24px, 96% 50%);
    }
  }
}

.c-select-custom {
  vertical-align: middle;

  &::before {
    display: none;
  }

  select {
    @include imgRetina("../images/common/icon/chevron-down", "png", 12px, 12px, 92% 50%);
  }
}

.c-header {

  &__search-overlay {
    display: none;
  }

  &__call-info {
    display: block;
  }

  &__back-text {
    display: block;
  }
}

html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
  opacity: 1 !important;
  transform: none !important;
}

.c-pagination__results {
  vertical-align: middle;
}

.c-tabs-nav {

  &.is-overflowed::after {
    display: none;
  }
}

.c-nav-vertical {

  &__link > span {
    display: block;
    flex: 1 1 auto;
  }
}

.c-button-dropdown {

  &__menu-wrap.has-overflow::after {
    display: none;
  }
}

.c-table {

  tbody tr:hover {
    box-shadow: none;
  }

  &--fixed-col {
    position: relative;
    padding-left: rem(150px);

    th:first-child,
    td:first-child {
      position: absolute;
      left: 0;
      width: rem(150px);
    }

    .c-table__subheading {
      min-height: rem(36px);
    }
  }

  .c-table__IE-wrap {
    width: 100%;
    overflow: auto;
  }
}

.c-report {

  &__main-col {
    width: calc(100vw - #{rem(200px + $o-container__gutter + $grid-gap-single)});
    transition: width 0.3s ease;

    @media (min-width: $xl) {
      width: rem($o-container - 200px);
    }

    &.is-full {
      width: calc(100vw - #{rem(24px + $o-container__gutter + $grid-gap-single)});

      @media (min-width: $xl) {
        width: rem($o-container - 24px);
      }
    }
  }
}

.c-report-nav {

  &__menu {
    > li > .c-report-nav__link {
      padding-right: 40px;
    }
  }

  &__link {
    display: block;
    position: relative;
    min-height: 0;
    padding-top: rem(12px);
    padding-bottom: rem(12px);
  }

  &__submenu-toggle {
    position: absolute;
    right: 0;
    top: 50%;
    margin: -8px 0 0;
  }
}
